import React, {useState} from "react";
import Stars from "./stars";
const ChamberReviews = () =>{

    const [isMore, setMore] = useState(false)
    const handleClick = () => {
        return setMore(!isMore)
    }
        
    const reviewsChamb = [
        {
            name:"JIMES KREIN",
            date:"May 14th, 2021",
            text:"Jesus come to clean our vents at our house. He did a very good job. I highly recommend this company to clean your air vents. Very professional. ",
            stars:5
        },
        {
            name:"APRIL EATON",
            date:"May 2nd, 2021",
            text:"Within an hour he had repaired the problem and cleaned my dryer vent. Very professional and left a tidy workspace. I will definitely use him again ",
            stars:5
        },
        {
            name:"JENNIFER RILEY",
            date:"Mar 14th, 2021",
            text:"I called for quote and they were able to come out the following day to clean my air duct. They were very accommodating and professional. I'll definitely use them in the future and recommend them to anyone looking to get their air ducts or dryer vents cleaned. ",
            stars:5
        },
        {
            name:"DEB SCHIRO",
            date:"Mar 5th, 2021",
            text:"We had our dryer vent replaced by Pablo and he did a great job. He was courteous and got the job done quickly and effectively. We called to inquire about a quote on a Friday and he was here the following Monday morning to complete the work, which is awesome as we were having air flow issues and our clothes weren't drying. I would recommend Comfort Air! Service: Duct and vent repair ",
            stars:5
        },
        {
            name:"DAN MCARDLE",
            date:"Feb 16th, 202",
            text:" ",
            stars:5
        },
        {
            name:"CAROL MACKENZIE ",
            date:"Jan 23rd, 2021",
            text:"Knowledgeable, capable and competent ",
            stars:5
        },
        {
            name:"MARY ANN MIRAFLOR",
            date:"Jan 21st, 2021",
            text:" ",
            stars:5
        },
        {
            name:"SHELBY HEMMERLING",
            date:"Jan 20th, 2021",
            text:"With not having my air ducts cleaned before, i wasn’t sure who was the best or not or what a good price was for everything. After doing research and making calls i found This company and they answered all my questions right there on the phone. They were on time and told me everything that they were going to do step by step and showed me the air vents before and after and even the dryer vents. I was Very happy with the work and they made sure all of them were 100% cleaned out before they left. Definitely reasonable $$ for the size of my house and the amount of vents. There were at my house for 5 hours but it needed that long and I’m so happy and one less thing that i have To worry about. Service: Duct and vent cleaning",
            stars:5
        },
        {
            name:"FELICIANA MENDOZA PABLO",
            date:"Jan 16th, 2021",
            text:"I called this company they came on time i highly recommend this Company great price highly recommend",
            stars:5
        },
        {
            name:"M S",
            date:"Jan 15th, 2021",
            text:"He was very prompt and did a thorough job",
            stars:5
        },
        {
            name:"MARYANN MIYOSHI",
            date:"Jan 15th, 2021",
            text:"We called for help with a foul odor from our heating ducts. They were very quick to respond and the problem was found (dead animal) and removed on the same visit. Options were given for duct cleaning, but we did not feel forced to perform any work not requested.",
            stars:5
        },
        {
            name:"RICHARD LILJA",
            date:"Jan 10th, 2021",
            text:" ",
            stars:5
        },
        {
            name:"ALFRED KITCHENS",
            date:"Jan 6th, 2021",
            text:" ",
            stars:5
        },
        {
            name:"KRISTOFF KIM",
            date:"Dec 23rd, 2020",
            text:"They were excellent, really super. There is no beating their prices. I use their air duct cleaning all the time for my house and office. They are never confused, they always know how to do everything and do it for a good price.",
            stars:5
        },
        {
            name:"KYUNGWOO LEE",
            date:"Dec 23rd, 2020",
            text:"One of my vent at my office does not have hot air. I called this company and they came on time. They explained me about the problem which is disconnection of duct under the crawlspace. They not only fixed the issue and also took care of an insulating issue in another vent. The price is reasonable, and I'm happy about their work. I highly recommend this company. Service: Duct and vent repair",
            stars:5
        },
        {
            name:"DAD’S CORNER",
            date:"Dec 9th, 2020",
            text:"Wonderful duct cleaning service!",
            stars:5
        },
        {
            name:"DOUGLAS SOMERS",
            date:"Nov 26th, 2020",
            text:"Jesus came to the house immediately after calling him and removed the smell which was a dead rat. I highly recommend his service for any and all air duct cleaning service he exlianed the problem and removed it. It was a pleasure dealing with him. Very professional",
            stars:5
        },
        {
            name:"RICHARD CHARM",
            date:"Nov 25th, 2020",
            text:" ",
            stars:5
        },
        {
            name:"MONT BOOTH",
            date:"Nov 20th, 2020",
            text:"True professionals. I contacted Comfort Air Duct Cleaning to come over and clean my dryer ducts. I wasn't sure how dirty my ducts were until I saw how much was cleaned out of them. Wow, I am sure glad that I scheduled this when I did... my ducts were almost totally blocked. Now my dryer runs much more efficiently. The guys at Comfort Air Duct Cleaning did such a great job. They showed up on time, completed the job and cleaned around the work space. I will definitely call them next year to have this service done again! Services: Duct and vent cleaning, Duct and vent repair ",
            stars:5
        },
    ]

    return(
        <section className="w-full py-20">
            <h2 className="text-center">Our Reviews</h2>
            <h4 className="flex place-content-center text-blue-700 underline my-10">
                <a href="https://www.chamberofcommerce.com/united-states/washington/poulsbo/air-duct-cleaning-service/1340777101-comfort-air-duct-cleaning-attic-cleaning-and-dryer-vent-cleaning-service-crawl-space-cleaner-in-poulsbo-wa"
                target="_blank"
                rel="noreferrer"
                className="flex">
                    <img
                    alt="logochamber"
                    src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/chamber.png?alt=media&token=aea5f470-2132-4c12-89b0-ccd8620809f5"
                    className="w-[50px] mr-1"/>
                    Chamber Of Commerce
                </a> 
                </h4>

                {
                    isMore?
                    reviewsChamb.map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium">{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>

                        </section>
                        )
                    })
                    :
                    reviewsChamb.slice(0,4).map((item, index) =>{
                        return(
                            <section key={index} className="flex flex-col border-b-2 border-gray-300 w-4/5 min-h-[100px] mx-auto">
                            <div className="flex justify-between p-5">
                                <div className="flex">
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
                                alt="userphotodeatult"
                                 className="w-[50px] shadow-md rounded-[50%]"/>
                                <div className="ml-3">
                                    <span className="font-medium" >{item.name}</span>
                                    <Stars 
                                    score={item.stars}
                                    />
                                </div> 
                                </div>
                                <span className="">{item.date}</span>
    
                            </div>
                            <div className="p-5">
                                <p>{item.text}</p>
                            </div>
                        </section>
                        )
                    })
                }


                <button className="bg-red-500 p-5 rounded-lg mx-auto block mt-20 text-white" onClick={handleClick} >
                        {
                            isMore?
                            "Show Less"
                            :
                            "Show More"
                        }
                </button>
        </section> 
    );

}

export default ChamberReviews